import React, { useState } from "react"
import { useForm } from "@formspree/react"
import validate from "../constants/validate-contact"

export default function ContactForm() {
  const [state, handleSubmit] = useForm(process.env.GATSBY_CONTACT_FORM)
  const [values, setValues] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  })
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  })
  const [username, setUsername] = useState({
    name: "",
  })

  const handleChange = event => {
    const { name, value } = event.target
    setValues({
      ...values,
      [name]: value,
    })
    //console.log("values: ", values)
  }
  const manageSubmit = e => {
    e.preventDefault()

    // other logic or error checking logic here
    setErrors(validate(values))
    //console.log("errors: ", errors)
    //console.log("values: ", values)

    if (
      values.name.length > 0 &&
      values.subject.length > 0 &&
      values.email.length > 0 &&
      values.message.length > 0
    ) {
      //console.log("values in if statement: ", values)
      setUsername(values.name)
      handleSubmit(e)
      resetForm()
      //console.log("Submitted successfully")
    }
  }

  const resetForm = () => {
    values.name = ""
    values.email = ""
    values.subject = ""
    values.message = ""
  }

  return (
    <div className="uk-grid-item-match uk-flex-middle uk-width-expand@m">
      <div className="uk-panel">
        <div className="uk-margin">
          {" "}
          <form onSubmit={manageSubmit} noValidate>
            <label className="uk-form-label" htmlFor="name">
              Name
            </label>
            <input
              id="name"
              type="text"
              name="name"
              placeholder="Enter your name..."
              onChange={handleChange}
              value={values.name}
              className="uk-input uk-margin-small-bottom"
            />
            {errors.name && (
              <div className="uk-alert-danger" uk-alert="true">
                <p className="uk-form-danger">{errors.name}</p>
              </div>
            )}
            <label className="uk-form-label" htmlFor="name">
              Subject
            </label>
            <input
              id="subject"
              type="text"
              name="subject"
              onChange={handleChange}
              value={values.subject}
              placeholder="Enter a subject..."
              className="uk-input uk-margin-small-bottom"
            />
            {errors.subject && (
              <div className="uk-alert-danger" uk-alert="true">
                <p className="uk-form-danger">{errors.subject}</p>
              </div>
            )}
            <label className="uk-form-label" htmlFor="name">
              email
            </label>
            <input
              id="email"
              type="email"
              name="email"
              onChange={handleChange}
              value={values.email}
              placeholder="Enter your email..."
              className="uk-input uk-margin-small-bottom"
            />
            {errors.email && (
              <div className="uk-alert-danger" uk-alert="true">
                <p className="uk-form-danger">{errors.email}</p>
              </div>
            )}
            <label className="uk-form-label" htmlFor="name">
              message
            </label>
            <textarea
              id="message"
              type="text"
              name="message"
              rows="5"
              onChange={handleChange}
              value={values.message}
              placeholder="Enter your message..."
              className="uk-textarea uk-margin-small-bottom"
            />
            {errors.message && (
              <div className="uk-alert-danger" uk-alert="true">
                <p className="uk-form-danger">{errors.message}</p>
              </div>
            )}
            <button
              type="submit"
              className="uk-button uk-button-default uk-button-medium uk-border-pill uk-animation-fade uk-margin-top buttonHover"
              style={{ backgroundColor: "#617F7F", color: "#FFFFFF" }}
              disabled={state.submitting}
            >
              Submit your message
            </button>

            {state.succeeded && (
              <div className="uk-alert-success" uk-alert="true">
                <a href="/" className="uk-alert-close" uk-close="true">
                  {null}
                </a>
                <p>
                  Thank you {username}, your message has been sent successfully!
                </p>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}
